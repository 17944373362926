.root{
    width: 100%;
    display: flex;
    flex-direction: column;
    position: relative;
    position: relative;
    overflow: hidden;
    text-align: center;
    padding: 20px;
    // border-top: 1px solid var(--pro-outline-border);
    .icon{
        width: auto;
        margin-bottom: 5px;
        span{
            font-size: 50px !important;
            opacity: .3;
        }
    }
}
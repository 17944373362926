.btn{
    padding: 8px 10px;
    padding-right: 25px;
    color: var(--pro-invoice-label);
    &::after{
        content: '';
        position: absolute;
        width: 7px;
        height: 7px;
        border-left: 2px solid var(--pro-dark);
        right: 10px;
        border-bottom: 2px solid var(--pro-dark);
        top: 50%;
        transform: translate(0, -50%) rotate(-45deg);
        opacity: 0.8;
    }
}
.root{
    :global{
        .rmp-container{
            .rmp-popup{
                width: 13rem;
                padding: 1rem 0.8rem;
                border: none !important;
                transform: translate3d(0, 20px, 0);
                background-color: #ffffff !important;
                right: -65px;
                .rmp-pad{
                    width: 13rem;
                    >div {
                        >label{
                            color: #172B4D;
                            font-size: 14px;
                            line-height: 2;
                            font-weight: 600;
                        }
                        >i.rmp-btn{
                            font-size: 0;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            width: 24px;
                            height: 24px;
                            &::after{
                                content: '';
                                background-image: url(../../../../assets/images/date_left_icon.png);
                                background-repeat: no-repeat;
                                display: inline-block;
                                width: 24px;
                                height: 24px;

                            }
                            &.next::after{
                                transform: rotate(180deg);
                            }
                            &:hover{
                                background-color: #f0f1f3 !important;
                            }
                        }
                    }
                    >ul{
                        >li{
                            font-size: 11px;
                            line-height: 3;
                            font-weight: 700;
                            padding: 0;
                            &:not(.active):hover{
                                background-color: #f0f1f3 !important;
                            }
                            &.active{
                                color: #660047 !important;
                                background-color: transparent !important;
                            }
                        }
                    }
                }
            }
        }
    }
}

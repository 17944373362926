.root {
  width: 100%;
  position: relative;
}

.heading {
  :global(.pro-ttl) {
    font-size: 16px;
  }
}


.dashboardTable {
   
  transform: scale(0.9);
  
}

.dashboardTable:hover {
  cursor: pointer;

  transform: scale(1);
  transition: transform 0.3s
}
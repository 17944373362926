.root {
  width: 100%;
  position: relative;
  display: flex;
  flex-wrap: wrap;
  border-bottom: 1px solid var(--pro-secondary);
  .icon {
    width: 2rem;
    height: 2rem;
    border-radius: 50%;
    overflow: hidden;
    background-color: var(--pro-secondary);
    span {
      font-size: 20px;
    }
  }
  .text {
    width: 100%;
    max-width: calc(100% - 2rem);
    display: flex;
    flex-direction: column;
  }
  .body {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    position: relative;
  }
  :global {
    .avatar-sqr {
      width: 24px;
      display: block;
      border-radius: 4px;
      margin-right: 4px;
      margin-bottom: 4px;
      overflow: hidden;
    }
  }
}

.profileTooltip {
    position: absolute;
    top: 100%;
    left: 50%;
    transform: translateX(-50%);
    background-color: #f1f1f1;
    padding: 10px;
    border-radius: 4px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    z-index: 999;
  }
  
  .dashboardTable {
    cursor: pointer;
    transition: all 0.3s;
  }
  
  .dashboardTable:hover {
    box-shadow: 2px 2px 10px rgb(0 0 0 / 19%);
  }
  
  .avatar_list_container {
    position: fixed;
    max-width: 250px;
    width: 100%;
    opacity: 0;
    --box-shadow: 0, 0, 0;
    box-shadow: 1px 1px 10px 0px rgba(var(--box-shadow), 0.1);
    transform: translateY(var(--transform, 5px));
    pointer-events: none;
    z-index: 3;
    --wrapper-height: 30px;
  
    top: calc((var(--top) * 1px) + var(--wrapper-height));
    left: calc(var(--left) * 1px);
    &.active {
      transition: transform 0.3s, opacity 0.3s;
      opacity: 1;
      transform: translateY(0);
    }
    &.top {
      top: calc((var(--top) - var(--card-height) - 15) * 1px);
    }
  }
  
  .avatar_container {
    position: relative;
    z-index: 3;
    .img_wrap {
      max-width: 18px;
      min-width: 18px;
      flex: 1;
    }
  }
  
  [data-theme*="dark"] {
    .avatar_list_container {
      --box-shadow: 255, 255, 255;
    }
  }
  
  .no_padding {
    padding-top: 0 !important;
  }
  
  .user_select{
    min-width: 250px;
    :global(.avatar-container){
      position: absolute;
      top: 0;
      max-width: 100%;
    }
    :global(.avatar-container .name){
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    :global(.pro-input .pro-input__menu){
      z-index: 99;
    }
  }
  
  .selectBox_table{
      min-height: calc(100vh - 175px);
  }
  

  .icon_text_wrap > div{
    cursor: default !important;
    p{
      color: inherit !important;
    }
  }
.root{
    padding-bottom: 10px;
    border-bottom: 1px solid var(--pro-border-color);
    .name{
        font-size: 1.125rem;
        font-weight: 500;
        margin-bottom: 2px; 
    }
    :global(.pro-avatar){
        --wac-avatar-size: 2.625rem;
    }
    div + .content{
        margin-left: 12px;
    }
}
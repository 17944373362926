.root{
    width: 100%;
    position: relative;
}

.root_rule{
    width: 100%;
    position: relative;
    background-color: var(--pro-secondary);
    border: 1px solid var(--pro-outline-border);
    border-radius: 4px;
    &.error_rule{
        border-color: var(--pro-danger);
    }
    label:empty{
        display: none;
    }
    :global{
        .row{
            margin-top: calc(-1 * var(--pro-gutter-y));
            margin-right: calc(-0.2 * var(--pro-gutter-x));
            margin-left: calc(-0.2 * var(--pro-gutter-x));
            > *{
                padding-right: calc(var(--pro-gutter-x) * 0.2);
                padding-left: calc(var(--pro-gutter-x) * 0.2);
            }
        }
        
    }
}

.deleteAction{
    width: 1.7778rem;
    :global{
        .pro-btn-link{
            padding: 0px;
            margin: 0px;
            width: 20px;
            height: 32px;
            color: var(--pro-dark);
            &:hover{
                color: var(--pro-primary);
            }
        }
    }
}

.error_text{
    color: var(--pro-danger);
    font-size: 12px;
}
.root{
    width: 100%;
    position: relative;
}

.followUp_list_wrap{
    overflow: hidden;
    // position: relative;
    // &::before{
    //     content: '';
    //     position: absolute;
    //     left: 6px;
    //     top: 0;
    //     height: 100%;
    //     // border-left: 1px dashed var(--pro-border-color);
    // }
    .item{
        position: relative;
        padding-left: 24px;
        .line_wrap{
            position: absolute;
            height: 100%;
            width: 1px;
            left: 6px;
            top: calc(-50% + 22px);
            background-image: linear-gradient( to top, var(--pro-border-color), transparent);
            &::before{
                content: '';
                position: absolute;
                left: 0;
                top: 0;
                height: 100%;
                border-left: 0;
            }
            &::after{
                content: '';
                position: absolute;
                width: 12px;
                height: 12px;
                border-radius: 50%;
                bottom: 0;
                left: 0;
                background-color: var(--pro-primary);
                transform: translateX(-50%);
                border: 1px solid var(--pro-light);
                z-index: 1;
            }
        }
        ~ .item{
            padding-top: 1rem;
            .line_wrap{
                background-color: transparent;
                &::after{
                    background-color: var(--pro-light);
                    border: 1px solid var(--pro-border-color);
                }
                &::before{
                    border-left: 1px dashed var(--pro-border-color);
                }
            }
        }
    }
}

.list_item{
    &_wrap{
        border: 1px solid var(--pro-border-color);
        background-color: var(--pro-secondary);
        border-radius: 6px;
        div + p{
            margin-top: 8px;
        }
        p + p{
            margin-top: 10px;
        }
    }
    .date + .list_item_wrap{
        margin-top: 3px;
    }
}  

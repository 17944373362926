.root{
    background-color: var(--pro-light);
    border-radius: 8px;
    .list_wrap{
        a{
            color: inherit;
        }
        p{
            margin-bottom: 0;
            ~ p {
                margin-top: 10px;
            }
        }
    }
    div + .list_wrap{
        margin-top: 10px;
    }
}
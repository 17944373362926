.dashboardTable {
    cursor: pointer;
    transition: all 0.3s;
  }
  
  .dashboardTable:hover {
    box-shadow: 2px 2px 10px rgb(0 0 0 / 19%);
  }
  

  .modal_root{
    &.desktop{
      --pro-modal-width : 1000px;
    }
    &.mobile{
      --pro-modal-width : 600px;
    }
    &.popup{
      --pro-modal-width : 400px;
    }
  }
  .reactSelectContainer{
    border: none !important;
    box-shadow: none !important;
    padding: 0 !important;
  }
.label{
    color: var(--pro-invoice-label);
}
.row_container{
    --pro-gutter-y: 1.4rem
}
.invoice_table{
    width: 100%;
    border-top: 1px solid var(--pro-dark);
    border-bottom: 1px solid var(--pro-border-color);
    tr{
        &:first-child{
            border-bottom: 1px solid var(--pro-border-color);
        }
    }
    th{
        padding: 8px 0;
        font-weight: 600;
        &:last-child{
            text-align: end;
        }
    }
    td{
        padding: 8px 0;
        font-weight: 500;
        &:last-child{
            text-align: end;
        }
    }
}
.user_detail_table{
    width: 100%;
    tr{
        width: 100%;
        td{
            width: 50%;
            padding: 8px 0;
        }
    }
}
.table_btm_content{
    border-bottom: 1px solid var(--pro-dark);
}
.total{
    font-size: 10px;
}
.logoWrapper{
    max-width: 131px;
    width: 100%;
}
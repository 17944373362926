.root {
  width: 100%;
  position: relative;
}
.custom_dropdown {
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  background-color: var(--pro-light);
  z-index: 9;
  border: 1px solid var(--pro-outline-border);
  border-radius: 5px;
  max-height: 180px;
  overflow-y: auto;
  ul li {
    a {
      padding: 0.5rem 0.8rem;
      cursor: pointer;
      transition: background-color 0.2s ease-in;
      color: var(--pro-dark);
      &:not(.selected):hover {
        background-color: var(--pro-input-option-hover-bg);
      }
      &.selected {
        background-color: var(--pro-primary);
        color: var(--pro-light);
      }
    }
  }
}

.input_wrap {
  :global(.pro-input__dropdown-indicator) {
    display: none;
  }
}

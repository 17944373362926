.root {
  width: 100%;
  position: relative;
}

.heading {
  :global(.pro-ttl) {
    font-size: 16px;
  }
}

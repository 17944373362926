.root{
    border-bottom: 1px solid var(--pro-outline-border);
    overflow-x: auto;
}
.root_item{
    width: 100%;
    min-width: max-content;
    &.active{
        border-bottom: 3px solid var(--pro-dark);
    }
    button{
        color: var(--pro-dark);
    }
}
.black_border_wrap{
    border-top: 1px solid var(--pro-dark);
    border-bottom: 1px solid var(--pro-dark);
}
.gray_border_wrap{
    border-bottom: 1px solid var(--pro-border-color);
}
.auto_height_wrap{
    > * {
        height: max-content;
        width: max-content;
    }
}
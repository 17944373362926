.text{
    width: 20%;
    height: 10px
}
.title{
    width: 15%;
    height: 25px;
}
.left_img{
    height: 40px;
}
.root{
    & ~ .root{
        padding-top: 12px;
        margin-top: 12px;
        border-top: 1px solid var(--pro-border-color);
    }
}
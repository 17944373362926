.reactSelectContainer {
  border: none !important;
  box-shadow: none !important;
  padding: 0 !important;
}

.dashboardTable {
  cursor: pointer;
  transition: all 0.3s;
}

.dashboardTable:hover {
  box-shadow: 2px 2px 10px rgb(0 0 0 / 19%);
}

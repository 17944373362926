.root{
    --pro-gutter-x: 10px;
    --pro-gutter-y : 16px;
    > div{
        width: 100%;
        max-width: 14.2857142857%;
        @media (max-width: 1599px) {
            max-width: 20%;
        }
        @media (max-width: 1199px) {
        max-width: 25%;
        }
        @media (max-width: 991px) {
        max-width: 33.333%;
        }
    }
}
.item{
    border-radius: 6px;
    border: 1px solid var(--pro-outline-border);
    :global{
        .letter{
            height: 6px;
            width: 40%;
            & + .letter{
                width: 30%;
            }
        }
    }
}
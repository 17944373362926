.footer_sticky{
    position: sticky;
    bottom: -20px;
    padding: 16px 0;
    background-color: var(--pro-light);
    z-index: 9;
}
.danger_text{
    color: red;
}
.success_text{
    color: green;
}
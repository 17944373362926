.fixed_position {
  position: fixed !important;
  top: 49px;
  z-index: 9;
  background: var(--pro-light);
  width: calc(100% - 88px);
  transition: width 0.25s ease-out, padding 0.25s ease-out;
  padding: 23px 0px 10px;
  & + div {
    padding-top: 32px;
  }
  :global(.pro-check-box) {
    margin-top: 6px;
  }
}

.horizontalSlider {
  width: 100%;
  max-width: 500px;

  height: 4px;
  background-color: var(--pro-range-bg);
  :global {
    .examplethumb {
      top: 1px;
      display: flex;
      justify-content: center;
      cursor: grab;
      outline: none;
      width: 16px;
      line-height: 2.8;
      color: var(--pro-input-text);
      &::before{
        transform: translateY(-50%);
        content: '';
        position: absolute;
        top: 0;
        left: 1px;
        width: 16px;
        height: 16px;
        border-radius: 50%;
        background-color: var(--pro-primary);
        border: 1px solid var(--pro-primary);
      }
      @media (max-width: 1199.98px) {
        width: 12px;
        height: 12px;
      }
      &.examplethumb-0::before{
        left: -1px;
      }
    }
    .exampleTrack-1 {
      height: 100%;
      background-color: var(--pro-primary);
    }
  }
}
.examplethumb {
  cursor: pointer;
  // position: absolute;
  // z-index: 100;
  background: #ffffff;
  border: 8px solid #3774ff;
  border-radius: 100%;
  display: block;
  box-shadow: 0 0 2px 0 rgb(0 0 0 / 44%);
}
.examplethumb.active {
  background-color: grey;
}
.exampleTrack {
  //position: relative;
  background: #ddd;
}
.exampleTrack.exampleTrack-0 {
  background: #83a9ff;
}
.horizontalSlider .exampleTrack {
  top: 20px;
  height: 4px;
}
.exampleTrack-1 {
  background-color: red;
}

:global(.expanded) {
  .fixed_position {
    width: calc(100% - 280px);
  }
}

.root {
  display: flex;
  border-bottom: 1px solid var(--pro-border-color);
  .img_wrap {
    max-width: 7.375rem;
    width: 100%;
    figure {
      position: relative;
      overflow: hidden;
      padding-bottom: 100%;
      border-radius: 5px;
      img {
        position: absolute;
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        object-fit: cover;
      }
    }
  }
  .list {
    margin-top: auto;
    li {
      position: relative;
      ~ li {
        padding-left: 7px;
        margin-left: 7px;
        &::after {
          content: "";
          position: absolute;
          left: 0;
          top: 2px;
          bottom: 2px;
          background-color: var(--pro-border-color);
          width: 1px;
        }
      }
    }
  }
}

.isPremium {
  background-color: #e3aa16;
  border-radius: 30px;
  color: #fff;
  line-height: 1.2;
  display: flex;
}

.isInactive {
  background-color: #0a0a0a;
  border-radius: 30px;
  color: #fff;
  line-height: 1.2;
  display: flex;
}

.isExpired {
  background-color: #0a0a0a;
  border-radius: 30px;
  color: #fff;
  line-height: 1.2;
  display: flex;
}

.isActive {
  background-color: #3ec658;
  border-radius: 30px;
  color: #fff;
  line-height: 1.2;
  display: flex;
}
.isPending {
  background-color: #a11d2b;
  border-radius: 30px;
  color: #fff;
  line-height: 1.2;
  display: flex;
}
.isDeleted {
  background-color: #a11d2b;
  border-radius: 30px;
  color: #fff;
  line-height: 1.2;
  display: flex;
}

.isBlocked {
  background-color: #a11d2b;
  border-radius: 30px;
  color: #fff;
  line-height: 1.2;
  display: flex;
}
.overview_wrap_list_item ~ .overview_wrap_list_item {
  border-top: 1px dashed var(--pro-border-color);
}

.table_wrap table tr td + td {
  padding-left: 2rem !important;
}

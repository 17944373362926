.root {
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  position: relative;
  background-color: var(--pro-auth-page-bg);
  justify-content: center;
  align-items: center;
  .root_inner {
    max-width: 423px;
    width: 60%;
  }
  .logoWrapper{
    width: 100%;
    max-width: 201.39px;
    margin-left: auto;
    margin-right: auto;
  }
  
}


.auth{
  width: 100%;
  position: relative;
  :global{
    .pro-btn-link{
      margin: auto;
    }
  }
}
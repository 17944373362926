.thumb {
    height: 2.4rem;
    border-radius: 3px;
    width: 8rem;
  }
  
  .item{
    border: 1px solid var(--pro-outline-border);
    align-items: center;
    > *{
      height: 0.8rem;
    }
  }
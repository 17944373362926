.root_overview_card {
  border-bottom: 1px solid var(--pro-outline-border);
}

.root_comments_card {
  border-bottom: 1px solid var(--pro-outline-border);
  .left_wrap {
    max-width: 60.3125rem;
    width: 100%;
    margin-right: 3rem;
    .profile_wrap {
      gap: 8px;
      .img_wrap {
        max-width: 24px;
        width: 100%;
        figure {
          border-radius: 50%;
          overflow: hidden;
        }
      }
      & + p {
        margin-top: 7px;
      }
    }
    > p {
      margin-bottom: 0;
      & + p {
        margin-top: 8px;
      }
    }
    div + .profile_wrap {
      margin-top: 7px;
    }
  }
}

.profile_icon {
  color: var(--pro-dark);
}

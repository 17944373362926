.root {
  width: 100%;
  position: relative;
  height: 100%;
  .inner {
    width: 100%;
    display: flex;
    flex-direction: column;
    border: 1px solid var(--pro-outline-border);
    border-radius: 6px;
    overflow: hidden;
    border-bottom: 3px solid #cfd3dc;
    transition: 0.3s all ease-in-out;
    height: 100%;
    padding: 1rem 1.125rem;
  }
  &:hover {
    .inner {
      border-bottom: 3px solid var(--pro-primary);
    }
  }
}
:global(.active) {
  .inner {
    border-bottom: 3px solid var(--pro-primary);
    border-color: var(--pro-primary);
    .icon {
      background-color: var(--pro-primary);
      border-color: var(--pro-primary);
      span {
        color: var(--pro-light);
      }
    }
  }
}

.inner_heading {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  .icon {
    width: 30px;
    height: 30px;
    border-radius: 6px;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid var(--pro-outline-border);
    transition: all 0.3s ease-in;
    span {
      font-size: 20px;
      color: var(--pro-primary);
    }
    & + h6 {
      flex: 1;
      margin-top: 4px;
    }
  }
}

.body {
  width: 100%;
  // margin-top: auto;
  h3 + .growth_percentage {
    margin-left: 10px;
  }
  .total_value {
    font-size: 22px;
  }
  .last_month {
    font-size: 12px;
    // margin-left: 25px;
  }
}

.growth_percentage {
  position: relative;
  color: var(--color);
  display: flex;
  align-items: center;
  padding-left: 10px;
  &::before {
    content: "";
    border-bottom: solid var(--bottom-size) var(--color);
    border-top: solid var(--top-size) var(--color);
    border-left: solid 3.5px transparent;
    border-right: solid 4px transparent;
    vertical-align: middle;
    position: absolute;
    left: 0;
  }
  &.increment {
    --bottom-size: 6px;
    --top-size: 0;
    --color: var(--pro-success);
  }
  &.decrement {
    --bottom-size: 0;
    --top-size: 6px;
    --color: var(--pro-danger);
  }
}

.footer {
  --wac-badge-bg: var(--pro-primary) !important;
  --wac-badge-border-color: var(--pro-primary) !important;
}

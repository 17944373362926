.root{
    width: 100%;
    position: relative;
}

.followUp_list_wrap{
    overflow: hidden;
    .item{
        position: relative;
        padding-left: 24px;
        &::before{
            content: '';
            position: absolute;
            left: 6px;
            top: 0;
            height: 100%;
            border-left: 1px dashed var(--pro-outline-border);
            width: 1px;
        }
        &::after{
            content: '';
            position: absolute;
            width: 12px;
            height: 12px;
            border-radius: 50%;
            bottom: calc(50% - 21px);
            left: 6px;
            background-color: var(--pro-light);
            transform: translateX(-50%);
            border: 1px solid var(--pro-outline-border);
            z-index: 1;
        }
        ~ .item{
            padding-top: 1rem;
        }
        &:first-child{
            &::before{
                border-left: 0;
                background-image: linear-gradient(to top , var(--pro-outline-border), transparent);
            }
            &::after{
                background-color: var(--pro-primary);
                border-color: var(--pro-primary);
            }
        }
        &:last-child{
            &::before{
                height: calc(50% + 12px);
            }
        }
    }
}

.list_item{
    &_wrap{
        border: 1px solid var(--pro-outline-border);
        background-color: var(--pro-staff-card-bg);
        border-radius: 6px;
        div + p{
            margin-top: 8px;
        }
        p + p{
            margin-top: 10px;
        }
    }
    .date{
        opacity: 0.8;
        & + .list_item_wrap{
            margin-top: 3px;
        }
    }
}  

.root{
    border: 1px solid var(--pro-outline-border);
    .graph{
        border-left: 1px solid var(--pro-outline-border);
        border-bottom: 1px solid var(--pro-outline-border);
        height:200px;
        width: 70%;
        align-items: end;
        .graph_item_wrap{
            height: 100%;
            align-items: end;
        }
        .graph_item{
            height: 65%;
            + .graph_item{
                height: 90%;
            }
        }
    }
}
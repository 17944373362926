.root{
 .range_wrap{
    background-color: var(--pro-secondary);
    height: 3px;
    width: 100%;
    position: relative;
    .range{
        position: absolute;
        width: 15px;
        height: 15px;
        border-radius: 50%;
        top: 50%;
        transform: translateY(-50%);
        --blink-duration: 1s;
        --blink-delay: 0.2s;
        &::after{
            content: '';
            display: inline-block;
            margin-top: 17px;
            width: 17px;
            height: 5px;
            background-color: var(--pro-secondary);
        }
    }
    .range_one{
        left: 30%;
    }
    .range_two{
        right: 30%;
    }
 }   
}
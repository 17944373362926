.dashboardTable {
  cursor: pointer;
  transition: all 0.3s;
}

.dashboardTable:hover {
  box-shadow: 2px 2px 10px rgb(0 0 0 / 19%);
}
.root{
  :global(.pro-btn-primary.loading){
    border: 1px solid var(--pro-primary);
    background-color: white;
  }
}
.root{
    .badge{
        border: 1px solid var(--pro-outline-border);
        border-radius: 50px;
        max-width: 90px;
    }
    .img_wrap{
        .img{
            width: 1.5rem;
            height: 1.5rem;
        }
    }
    .text_wrap{
        width: 100%;
        max-width: 600px;
        .text{
            width: 100%;
            & +.text{
                width: 85%;
            }
        }
    }
    & ~ .root{
        border-top: 1px solid var(--pro-outline-border);
    }
}
.root{
    .title{
        max-width: 20rem;
        width: 100%;
    }
    .date{
        width: 5rem;
    }
    & ~ .root{
        border-top: 1px solid var(--pro-outline-border);
    }
}
.root {
  width: 100%;
  position: relative;
  .date_input {
    :global(.input-wrap .pro-input) {
      min-width: 100% !important;
    }
  }
}

.error_text {
  white-space: initial;
  font-size: 12px;
  line-height: 1;
  margin-top: 10px;
  color: var(--pro-danger);
}

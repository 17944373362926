.root {
  width: 100%;
  position: relative;
  padding: 5px;
  max-width: 14.2857142857%;
  @media (max-width: 1599px) {
    max-width: 20%;
  }
  @media (max-width: 1199px) {
    max-width: 25%;
  }
  @media (max-width: 991px) {
    max-width: 33.333%;
  }
  .root_inner {
    width: 100%;
    position: relative;
    border-radius: 5px;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    height: 100%;
  }
  figure {
    overflow: hidden;
    img {
      width: 100%;
      transition: 0.3s transform cubic-bezier(0.54, 0.17, 0.19, 0.95);
    }
  }

  .root_text_wrapper {
    border: 1px solid var(--pro-outline-border);
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    flex: 1;
    display: flex;
    flex-direction: column;
  }
  .review,
  .root_text {
    width: 100%;
    position: relative;
    ul {
      position: relative;
      padding-right: 30px;
      li {
        margin-right: 5px;
        &:last-child {
          margin-right: 0;
        }
      }
      span {
        margin: 0px 2.5px;
        svg {
          position: relative;
          top: -1px;
        }
      }
    }
    p {
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
    }
  }
  &:hover {
    figure {
      img {
        transform: scale(1.08);
      }
    }
  }
  :global {
    .rounded-tick {
      position: absolute;
      right: 10px;
      top: 10px;
      span {
        color: #fff;
        font-size: 13px;
      }
      input {
        cursor: pointer;
      }
    }
  }
  &.shortlist {
    .badge {
      position: absolute;
      top: 11px;
      left: 9px;
      z-index: 2;
    }
  }
  &.unAvailable {
    pointer-events: none;
    .badge {
      position: absolute;
      top: 11px;
      right: 9px;
      z-index: 2;
    }
    .root_image {
      position: relative;
      &::after {
        content: "";
        position: absolute;
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        background-color: var(--pro-image-gray-overlay);
        opacity: 0.7;
        z-index: 1;
      }
    }
    &.shortlist {
      .badge {
        right: unset;
      }
    }
  }
}

.premium {
  position: relative;
  top: 3px;
  margin-left: 5px;
  display: inline-block;
  width: 16px;
  height: 16px;
  img {
    float: left;
  }
  span {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    color: #fff;
    font-size: 10px;
  }
}
.input_hover {
  opacity: 0;
  transition: 0.3s all ease-in-out;
}
.root:not(.unAvailable) {
  &:hover {
    .input_hover {
      opacity: 1;
    }
  }
}

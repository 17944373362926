.dashboardTable {
  cursor: pointer;
  transition: all 0.3s;
}

.dashboardTable:hover {
  box-shadow: 2px 2px 10px rgb(0 0 0 / 19%);
}
.selectBox_table {
  min-height: calc(100% - 100px);
}

.simple_report{
  max-width: 100%;
  .textInner{
    width: 100%;
    max-width: 100%;
    p{
        opacity: .7;
    }
}
:global(.row){
  >*{
    max-width: 50%;
  }
}
}
.reactSelectContainer{
  border: none !important;
  box-shadow: none !important;
  padding: 0 !important;
}
.root{
    width: 100%;
    display: flex;
    flex-direction: column;
    position: relative;
}
.readMore{
    border: none;
}
.heading{
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    pointer-events: all;
    justify-content: space-between;
    h6{
        margin-bottom: 0;
    }
    & + .body{
        margin-top: 0.9375rem;
    }
}
.body{
    width: 100%;
    display: flex;
    flex-direction: column;
    position: relative;
    .itemWrapper{
        width: auto;
        margin: 0px -5px;
        span:empty{
            display: none;
        }
        label{
            opacity: .8;
        }
    }
}
.actionBtn{
    width: auto;
    display: flex;
    align-items: center;
}
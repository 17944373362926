@import "~@wac-ui-dashboard/wac_component_library/scss/variables.scss";
.root_image_inner {
  width: 100%;
  position: relative;
  border-radius: $primary-border-radius;
  overflow: hidden;
  border: 1px solid var(--pro-light);
}

.root{
    width: 100%;
    position: relative;
  :global(.error-text){
    line-height: 1.3 !important;
  }
}

